import React from 'react';

import { formatCrypto, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmUser, CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import { AdmUserLink } from './admin-common';
import PageLayout from './PageLayout';
import { UserProfile } from './User';
export default class Users extends React.Component {
  render(): React.ReactNode {
    return (
      <>
        <PageLayout<UserProfile>
          request={getUsers}
          headerTitle={'admin.users.title'}
          searchInputHint={'admin.users.search-user'}
          defaultSortBy={'nickname'}
          tableColumns={[
            {
              id: 'nickname',
              caption: 'admin.users.table.nickname',
              render: (nickname) => <AdmUserLink nickname={nickname} />,
            },
            {
              id: 'email',
              caption: 'admin.users.table.email',
            },
            {
              id: 'telegramId',
              caption: 'admin.users.table.telegram-id',
            },
            {
              id: 'balance',
              caption: 'admin.users.table.balance',
              render: (balance, row) =>
                balance ? (
                  <>
                    {formatCrypto(balance, row.crypto)}&nbsp;{row.crypto}
                  </>
                ) : undefined,
            },
            {
              id: 'frozen',
              caption: 'admin.users.table.frozen',
              render: (frozen, row) =>
                frozen ? (
                  <>
                    {formatCrypto(frozen, row.crypto)}&nbsp;{row.crypto}
                  </>
                ) : undefined,
            },
            {
              id: 'isVerify',
              caption: 'admin.users.table.verified',
              render: (isVerify) => (
                <>
                  <span className={`color-${isVerify ? 'true' : 'false'}`}>
                    &bull;&nbsp;
                  </span>
                  <Txt k={`common.${isVerify ? 'yes' : 'no'}`} />
                </>
              ),
            },
            {
              id: 'isBaned',
              caption: 'admin.users.table.banned',
              render: (isBaned) => <Txt k={`common.${isBaned ? 'yes' : 'no'}`} />,
            },
          ]}
        />
      </>
    );
  }
}

export function assignUser(user: AdmUser, crypto?: string): UserProfile {
  const createdAt = parseDate(user.created_at);
  const lifeDays = createdAt
    ? Math.ceil(Math.abs(new Date().getTime() - createdAt.getTime()) / (1000 * 3600 * 24))
    : undefined;

  return {
    id: user.id,
    nickname: user.nickname,
    email: user.email,
    telegramId: user.telegram_id ? String(user.telegram_id) : '',
    crypto,
    balance: user.balance,
    frozen: user.frozen,
    rating: user.rating,
    lifeDays,
    dealsCount: user.deals_count,
    amount: user.amount,
    likes: user.likes,
    dislikes: user.dislikes,
    isVerify: user.is_verify,
    isTemporary: user.is_temporary,
    isBaned: user.is_baned,
    isMfaEnabled: user.is_mfa_enabled,
    maxPromocodeCount: user.max_promocode_count,
  };
}

export function getUsers(params: CommonRequestParams): Promise<UserProfile[]> {
  return new Promise((resolve) => {
    api.admin
      .users({ ...params, symbol: params.symbol?.toLowerCase() })
      .then((response) => {
        const users: UserProfile[] = [];
        for (const user of response) {
          users.push(assignUser(user, params.symbol));
        }
        resolve(users);
      })
      .catch(() => resolve([]));
  });
}
