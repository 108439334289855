import React, { ChangeEvent } from 'react';

import { setPreviousPage } from '../../config/history';
import { t } from '../../config/i18n';
import { getRowsPerPage, setRowsPerPage } from '../../helpers/settings';
import { cl } from '../../helpers/utils';
import { CloseButton, LinkEx, onClickOutside, Row } from '../common';
import InputEx from '../controls/InputEx';
import { PageText, PageTextProps } from '../controls/Page';
import SelectEx, { SelectExProps } from '../controls/SelectEx';
import TableEx, { TableExProps } from '../controls/TableEx';
import Txt from '../controls/Txt';
import { ArrowIcon, SearchIcon } from './icons';

type ChangeCryptoListener = (crypto: string) => void;

let settingsCrypto = 'BTC';
const changeCryptoListeners: ChangeCryptoListener[] = [];

export const disabledColor = '#8f8f8f';

export function onChangeCrypto(listener: ChangeCryptoListener) {
  if (changeCryptoListeners.indexOf(listener) === -1) {
    changeCryptoListeners.push(listener);
  }
}

export function offChangeCrypto(listener: ChangeCryptoListener) {
  const n = changeCryptoListeners.indexOf(listener);
  if (n !== -1) {
    changeCryptoListeners.splice(n);
  }
}

export function changeCrypto(crypto: string) {
  settingsCrypto = crypto;
  for (const listener of changeCryptoListeners) {
    listener(crypto);
  }
}

export function getSettingsCrypto(): string {
  return settingsCrypto;
}

export const AdmLink = (props) => (
  <LinkEx
    {...props}
    fontFamily={props.fontFamily || 'inherit'}
    size={props.size || 'inherit'}>
    {props.children}
  </LinkEx>
);

export const AdmUserLink = ({ nickname, ...props }) => (
  <AdmLink {...props} href={`/admin/users/${nickname}`}>
    {nickname}
  </AdmLink>
);

export const AdmDealLink = ({ dealId, ...props }) => (
  <AdmLink {...props} href={`/admin/deals/${dealId}`}>
    {dealId}
  </AdmLink>
);

export const AdmBidLink = ({ lot, ...props }) => (
  <AdmLink {...props} href={`/admin/bids/${lot}`}>
    {lot}
  </AdmLink>
);

export const AdmTable = (props) => (
  <TableEx
    {...props}
    className={'adm-table-ex'}
    backgroundColor={'unset'}
    headerColor={'#2f2f2f'}
    textColor={'white'}
    border={'0.1rem solid #3f3f3f'}
    headerFontSize={'1.2rem'}
    cellFontSize={'1.1rem'}
    noPadding
  />
);

export const AdmSearchInput = ({
  value,
  onChange,
  hint,
  width = '18rem',
}: {
  value?: string;
  onChange: (value?: string) => void;
  hint?: string;
  width?: string;
}) => {
  const [innerValue, setInnerValue] = React.useState(value);
  const timeout = React.useRef<any>(undefined);

  function change(value: string) {
    setInnerValue(value);

    clearInterval(timeout.current);
    timeout.current = setInterval(() => {
      clearInterval(timeout.current);
      onChange(value);
    }, 1000);
  }

  return (
    <InputEx
      type={'text'}
      className={'adm-search-input'}
      value={innerValue}
      onChange={change}
      img={<SearchIcon />}
      imgPos={'left'}
      imgWidth={'3rem'}
      width={width}
      fontSize={'1.2rem'}
      hint={t(hint)}
    />
  );
};

export const AdmInput = ({
  type,
  label,
  name,
  value,
  args,
  onChange,
}: {
  type: string;
  label;
  name?: string;
  value?: any;
  args?: Array<string>;
  onChange: (value?: any) => void;
}) => {
  function change(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  return (
    <div className={'adm-input-control'}>
      {!!label && (
        <label>
          <Txt k={label} args={args} />
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={change}
        name={name}
        onWheel={() => document.activeElement?.['blur']()}
      />
    </div>
  );
};

export const AdmInputRow = ({
  type,
  label,
  name,
  value,
  args,
  hint,
  onChange,
}: {
  type: string;
  label;
  name?: string;
  value?: any;
  args?: Array<string>;
  hint?: string;
  onChange: (value?: any) => void;
}) => {
  function change(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  return (
    <div className={'adm-input-control-row'}>
      <label>
        <Txt k={label} args={args} />
      </label>
      <input
        type={type}
        value={value}
        placeholder={hint}
        onChange={change}
        name={name}
        onWheel={() => document.activeElement?.['blur']()}
      />
    </div>
  );
};

export const AdmRowsPerPage = ({ onChange }: { onChange: (value: number) => void }) => {
  const [value, setValue] = React.useState(getRowsPerPage());

  function changeValue(val: any) {
    const rowsPerPage = Number(val);
    setValue(rowsPerPage);
    setRowsPerPage(rowsPerPage);
    onChange(rowsPerPage);
  }

  return (
    <div className={'adm-rows-per-page'}>
      <label>
        <Txt k={'admin.rows-per-page'} />
      </label>
      <SelectEx
        className={'adm-rows-per-page-select'}
        height={'3rem'}
        arrowColor={'#828891'}
        fontColor={'white'}
        hoverColor={'#3f3f3f'}
        backgroundColor={'#2f2f2f'}
        borderColor={'unset'}
        id={String(value)}
        options={[{ id: '10' }, { id: '15' }, { id: '20' }]}
        onChange={changeValue}
      />
    </div>
  );
};

export const AdmSelect = ({
  label,
  ...rest
}: {
  label: string;
} & SelectExProps) => {
  return (
    <div className="adm-select-control-row">
      <label>
        <Txt k={label} />
      </label>
      <SelectEx {...rest} className={'select'} backgroundColor="white" />
    </div>
  );
};

export const ReturnButton = () => (
  <div className={'adm-button return'} onClick={setPreviousPage}>
    <svg viewBox={'0 0 8 12'}>
      <path d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z" />
    </svg>
    <Txt k={'common.return'} />
  </div>
);

export const AdmSwitcher = ({
  value,
  onChange,
  className,
  disabled = false,
}: {
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}) => (
  <div
    className={`adm-switcher ${cl(value, 'enabled')} ${cl(disabled, 'disabled')} ${
      className ? className : ''
    }`}
    onClick={() => !disabled && onChange(!value)}>
    <div />
  </div>
);

export const AdmSwitcherChoice = ({
  value,
  onChange,
  className,
  choices,
}: {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  choices: string[];
}) => (
  <div
    className={`adm-switcher ${cl(value === choices[0], 'enabled')} ${
      className ? className : ''
    }`}
    onClick={() => onChange(value === choices[0] ? choices[1] : choices[0])}>
    <div />
  </div>
);

export const AdmSwitcherLabeled = ({
  value,
  onChange,
  className,
  disabled,
  label,
  labelClass,
  labelPosition = 'right',
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  className?;
  label;
  disabled?: boolean;
  labelClass?: string;
  labelPosition?: 'right' | 'left';
}) => (
  <div className={'adm-switcher-wrapper'}>
    {labelPosition === 'left' && (
      <span
        className={`adm-switcher-wrapper__label adm-switcher-wrapper__label-left ${
          labelClass ?? ''
        }`}>
        <Txt k={label} />
      </span>
    )}
    <AdmSwitcher
      disabled={disabled}
      value={value}
      onChange={onChange}
      className={className}
    />
    {labelPosition === 'right' && (
      <span className={`adm-switcher-wrapper__label ${labelClass}`}>
        <Txt k={label} />
      </span>
    )}
  </div>
);

export const AdmSwitcherLabeledChoice = ({
  value,
  onChange,
  className,
  label,
  labelClass,
  choices,
}: {
  value: string;
  onChange: (value: string) => void;
  className?;
  label;
  labelClass?: string;
  choices: string[];
}) => (
  <div className={'adm-switcher-wrapper'}>
    <AdmSwitcherChoice
      value={value}
      onChange={onChange}
      className={className}
      choices={choices}
    />
    <span className={labelClass}>
      <Txt k={label} />
    </span>
  </div>
);

export const CroppedText = ({ text }: { text?: string; width: string }) => (
  <div className={'cropped-text'} title={text}>
    {text}
  </div>
);

export function renderIcon(icon) {
  return icon ? icon({}) : undefined;
}

export const AdmTablePopup = ({
  className,
  children,
  onOpen,
}: {
  className?: string;
  children: React.ReactNode;
  onOpen?: () => void;
}) => {
  const [open, setOpen] = React.useState(false);

  function onOpenClick(e) {
    if (open) {
      close();
    } else {
      if (onOpen) {
        onOpen();
      }
      setOpen(true);
      onClickOutside(e.currentTarget, () => setOpen(false));
    }
  }

  function close() {
    setOpen(false);
  }

  return (
    <span className={`adm-table-popup ${cl(open, 'opened')}`} onClick={onOpenClick}>
      <ArrowIcon />
      <div
        className={`content ${className}`}
        onClick={(event) => event.stopPropagation()}>
        <CloseButton className={'close'} hint={'common.close'} onClick={close} />
        {children}
      </div>
    </span>
  );
};

interface AdmTextProps extends PageTextProps {
  disabled?: boolean;
}

export const AdmText = (props: AdmTextProps) => (
  <PageText
    {...props}
    size={props.size || '1.2rem'}
    color={props.disabled ? disabledColor : 'white'}
  />
);
