import React from 'react';

import { t } from '../../config/i18n';
import { showMessage } from '../../helpers/notifications';
import { getRowsPerPage } from '../../helpers/settings';
import { cl, formatCrypto, formatDateTime, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmTransaction, CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import {
  AdmSwitcher,
  AdmTable,
  AdmTablePopup,
  CroppedText,
  getSettingsCrypto,
} from './admin-common';
import PageLayout from './PageLayout';

export interface Transaction {
  id: number;
  type: string;
  toAddress: string;
  amount: number;
  crypto: string;
  commission: number;
  txHash: string;
  createdAt?: Date;
  processedAt?: Date;
  isConfirmed: boolean;
  isDeleted: boolean;
}
interface State {
  crypto: string;
  withdrawEnabled: boolean;
  searchTransaction?: string;
  rows: Transaction[];
  rowsLimit: number;
}

const WITHDRAW_ENABLED = 'withdraw_active';

export const getColumns = (onRestart, onDelete) => [
  {
    id: 'type',
    caption: 'admin.transactions.table.type',
    render: (type) => <Txt k={`admin.transactions.table.type-${type}`} />,
  },
  {
    id: 'toAddress',
    caption: 'admin.transactions.table.address',
    render: (toAddress) => <CroppedText text={toAddress} width={'11vw'} />,
  },
  {
    id: 'amount',
    caption: 'admin.transactions.table.amount',
    render: (amount, row) => (
      <>
        {formatCrypto(amount, row.crypto)}&nbsp;{row.crypto}
      </>
    ),
  },
  {
    id: 'commission',
    caption: 'admin.transactions.table.commission',
    render: (commission, row) => (
      <>
        {formatCrypto(commission, row.crypto)}&nbsp;{row.crypto}
      </>
    ),
  },
  {
    id: 'txHash',
    caption: 'admin.transactions.table.hash',
    render: (txHash) => <div className={'cropped-text'}>{txHash}</div>,
  },
  {
    id: 'createdAt',
    caption: 'admin.transactions.table.created-at',
    render: (createdAt) => formatDateTime(createdAt),
    minWidth: '11rem',
  },
  {
    id: 'processedAt',
    caption: 'admin.transactions.table.completed-at',
    render: (processedAt) => (processedAt ? formatDateTime(processedAt) : undefined),
    minWidth: '11rem',
  },
  {
    id: 'isConfirmed',
    caption: 'admin.transactions.table.completed',
    render: (isConfirmed, _, i, rows, setRows) => (
      <>
        <span className={`color-${isConfirmed ? 'true' : 'false'}`}>&bull;&nbsp;</span>
        <Txt k={`common.${isConfirmed ? 'yes' : 'no'}`} />
        {onRestart ? (
          <RestartTransactionBox onRestart={() => onRestart(i, rows, setRows)} />
        ) : undefined}
      </>
    ),
    width: '8rem',
  },
  {
    id: 'isDeleted',
    caption: 'admin.transactions.table.deleted',
    render: (isDeleted, _, i, rows, setRows) => (
      <>
        <Txt k={`common.${isDeleted ? 'yes' : 'no'}`} />
        {onDelete ? (
          <DeleteTransactionBox
            isDeleted={isDeleted}
            onDelete={() => onDelete(i, isDeleted, rows, setRows)}
          />
        ) : undefined}
      </>
    ),
    width: '7rem',
  },
];

export const deleteTransaction = (
  n: number,
  isDeleted: boolean,
  rows: Transaction[],
  setRows: (data: Transaction[]) => void,
) => {
  api.admin
    .deleteTransaction(rows[n].id, !isDeleted)
    .then(() => {
      rows[n].isDeleted = !isDeleted;
      setRows([...rows]);
    })
    .catch(() => undefined);
};

export const restartTransaction = (
  n: number,
  rows: Transaction[],
  setRows: (data: Transaction[]) => void,
) => {
  api.admin
    .restartTransaction(rows[n].id)
    .then(() => {
      setRows([...rows]);
      showMessage('info', t('admin.transactions.restarted'));
    })
    .catch(() => undefined);
};
export default class Transactions extends React.Component {
  public state: State = {
    crypto: getSettingsCrypto(),
    withdrawEnabled: true,
    rows: [],
    rowsLimit: getRowsPerPage(),
  };

  handleWidrawStatus = (status) => {
    this.setState({ withdrawEnabled: status });
  };

  enableWithdraw = (withdrawEnabled: boolean) => {
    const { crypto } = this.state;
    api.admin
      .updateCryptoSettings(
        crypto.toLowerCase(),
        WITHDRAW_ENABLED,
        String(withdrawEnabled),
      )
      .then(() => this.setState({ withdrawEnabled }))
      .catch(() => undefined);
  };

  render(): React.ReactNode {
    const { withdrawEnabled } = this.state;

    return (
      <PageLayout<Transaction>
        request={getTransactions}
        headerTitle={'admin.transactions.title'}
        searchInputHint={'admin.transactions.search-transaction'}
        defaultSortBy={'createdAt'}
        additional={{
          contentComponent: (
            <div className={'adm-withdraw-box'}>
              <AdmSwitcher value={withdrawEnabled} onChange={this.enableWithdraw} />
              <span className={cl(withdrawEnabled, 'enabled')}>
                <Txt k={'admin.transactions.withdraw'} />
              </span>
            </div>
          ),
          functions: { handleWidrawStatus: this.handleWidrawStatus },
        }}
        tableColumns={getColumns(restartTransaction, deleteTransaction)}
      />
    );
  }
}

const DeleteTransactionBox = ({
  isDeleted,
  onDelete,
}: {
  isDeleted: boolean;
  onDelete: () => void;
}) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    <div className={'adm-button delete-transaction'} onClick={onDelete}>
      <span>
        <Txt k={`admin.transactions.table.${isDeleted ? 'restore' : 'delete'}`} />
      </span>
    </div>
  </AdmTablePopup>
);

const RestartTransactionBox = ({ onRestart }: { onRestart: () => void }) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    <div className={'adm-button restart-transaction'} onClick={onRestart}>
      <span>
        <Txt k={'admin.transactions.table.restart'} />
      </span>
    </div>
  </AdmTablePopup>
);

export function getTransactions(
  params: CommonRequestParams & { user?: string },
  additionalFunctions?: Record<string, any>,
): Promise<Transaction[]> {
  return new Promise((resolve) => {
    Promise.all([
      api.admin.transactions({ ...params, symbol: params.symbol?.toLowerCase() }),
      additionalFunctions
        ? api.admin.cryptoSettings(getSettingsCrypto().toLowerCase())
        : undefined,
    ])
      .then(([response, settings]) => {
        const transactions: Transaction[] = [];
        for (const transaction of response) {
          transactions.push(assignTransaction(transaction));
        }
        resolve(transactions);
        additionalFunctions &&
          additionalFunctions.handleWidrawStatus(settings?.withdraw_active);
      })
      .catch(() => resolve([]));
  });
}

export function assignTransaction(t: AdmTransaction): Transaction {
  return {
    id: t.id,
    type: t.type,
    toAddress: t.to_address,
    amount: t.amount,
    crypto: getSettingsCrypto(),
    commission: t.commission,
    txHash: t.tx_hash,
    createdAt: parseDate(t.created_at),
    processedAt: parseDate(t.processed_at),
    isConfirmed: t.is_confirmed,
    isDeleted: t.is_deleted,
  };
}
